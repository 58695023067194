.full-screen-error {
    height: 100vh;
    width: 100vw;
    position: fixed;
    background: #fff;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 50000;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
        max-width: 400px;
        margin-bottom: 50px;
        cursor: default !important;
    }
}