$color-primary: #11A387;
$color-base-grey: #C9CACD;
$color-zomp: #E7F6F3;
$color-zomp-border: #CFEDE7;
$color-light-grey: #F5F5F5;
$color-login-background: #E5E5E5;
$color-primary-text: #4B5054;
$color-secondary-text: #93959B;
$color-hyperlink-blue: #3984DA;
$color-light-green: #E7F6F3;
$color-dark-grey: #222933;
$color-light-grey: #FAFAFA;
$color-info-light: #E3EFFF;
$color-product-text: #383E42;

$color-red: 'red';
$color-blue: #4FB2D6;
$color-grey: #5A6474;
$color-black: #302D3C;
$color-pink: #F398DF;
$color-orange: #FEBF10;
$color-dark-blue: #3F7BBE;
$color-light-green: #eff5f5;
$color-background-grey: #F6F7F9;
$color-display-grey: #F0F0F4;
$color-grey-input: #D0D0D0;
$color-green-input-success: #2BAC47;
$color-red-input-error: #C83532;
$color-orange-input-warning: #EF8943;
$color-table-header: #5A6474;
$color-table-content: #151515;
$color-dark-grey: #2D3748;
$color-overflow-hover: #F5F8FF;
$color-grey-footer: #626770;
$color-grey-login-input: #EDEDED;
$color-dark-grey-uhi: #093624;
$bright-green: #5CC1A6;
$color-golden : #F2D479;
$color-light-skyblue: #E3F0F5;
$color-dark-blue-grey: #131F2D;
$color-light-yellow : #E7E3D5;
$color-blue-grey: #5D636E;
$color-light-blue-grey: #C3C7CE;
$color-table-bar: #E9E9EA;
$color-warning-yellow : #fffbe6;
$color-light-blue-bg: #E1EDF9;
$color-warning-yellow-border: #ffe58f;
$color-red-confirm: #EB6262;
$color-process-blue: #227AE5;

$max-width: 1168px;
// $mobile-breakpoint: 575px;
$mobile-breakpoint: 992px;
$desktop-breakpoint: 992px;

// retail color 
$retail-color-primary: #11A387;
$retail-color-primary-lighter: #11a38639;
$retail-color-black: #2C2C2C;
$retail-color-secondary-text: #4B5054;
$retail-color-tertiary-text: #93959B;
$retail-color-cool-grey: #5D636E;
$retail-color-form-label: #5F6467;
$retail-color-link: #3984DA;
$retail-color-border: #C9CACD;
$retail-color-background-primary: rgba(218, 251, 245, 0.2);
$retail-color-background-secondary: rgba(201, 202, 205, 0.2);
$retail-color-background-tertiary: #FAFAFA;
$retail-color-background-footer: #222933;
$retail-color-red-input-error: #C83532;
$retail-color-primary-text: #4B5054;
$retail-color-text-grey: #828282;
$retail-color-text-light-grey : #A8A8A8;
// width 
$retail-max-width: 1168px;
$retail-mobile-breakpoint: 575px;