@import '../../../scss/mixins';
@import '../../../scss/variables';

.retail-layout {
    .retail-footer {
        background: url('/assets/retail/images/footerbg.svg');
        background-repeat: no-repeat;
        background-position: bottom left 20%;
        background-attachment: fixed;

        @include spacing(0, (80px 16px 48px 16px), 0, (80px 16px 48px 16px));
        background-color: #222933;

        .footer-heading {
            @include text(12px, white, 600, 14.52px);
            text-transform: uppercase;
        }

        .footer-links {
            @include text(14px, white, 400, 16.8px);
            @include cta();

            &:hover {
                text-decoration: underline;
                // color: var(--primary-color);
            }
        }

        .footer-badges {
            margin-top: 48px
        }

        .footer-brand-name {
            @include text(32px, white, 600, 38px);
            @include spacing(0, (8px 0 0px 0), 0, (8px 0 0px 0));
        }

        .footer-tagline {
            @include text(14px, white, 400, 17.5px);
            @include spacing(0, (8px 0 16px 0), 0, (8px 0 16px 0));
        }

        .footnote {
            @include text(12px, white, 400, 14.4px);
            margin-top: 64px;
        }
    }
}