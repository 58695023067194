@import '../../../scss/variables.scss';

.toggle-switch {
    margin: 0 8px;

    // .ant-switch-handle {
    //     height: 14px;
    //     width: 14px
    // }


    .ant-switch {
        background-color: #F5F5F5;
        // height: 20px !important;
        // width: 40px !important;

        .ant-switch-handle::before {
            background-color: var(--primary-color);
        }

        @media screen and (max-width: 991px) {
            background-color: #F5F5F5;

            .ant-switch-handle::before {
                background-color: var(--primary-color);
            }
        }
    }

    .ant-switch-checked {
        background-color: var(--primary-color);


        .ant-switch-handle::before {
            background-color: #4B5054;
        }

        @media screen and (max-width: 991px) {
            background-color: var(--primary-color);

            .ant-switch-handle::before {
                background-color: #4B5054;
            }
        }
    }
}