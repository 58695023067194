@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    // margin-right: 10px;

    &-primary {
        padding: 8px 16px;
        border-radius: 4px;
        border: none;
        background-color: var(--primary-color);
        color: white;
        min-width: 120px;

        &:hover {
            cursor: pointer;
            background-color: var(--primary-color);
            color: white !important;
        }

        &.disabled {
            background-color: $color-base-grey;
            cursor: not-allowed;
        }

        &.block {
            display: block;
            width: 100%;
            height: 48px;
        }
    }

    &-secondary {
        border-radius: 4px;
        padding: 8px 16px;
        border: 1px solid var(--primary-color);
        box-sizing: border-box;
        background-color: white;
        color: var(--primary-color);
        min-width: 120px;

        &:hover {
            background-color: var(--primary-color);
            color: white !important;
            cursor: pointer;
        }

        &.disabled {
            border-radius: 4px;
            border: 1px solid #C9CACD;
            background: #FFF;
            cursor: not-allowed;
            color: #C9CACD !important;
        }

        &.block {
            display: block;
            width: 100%;
            height: 48px;
        }
    }

    &-tertiary {
        background-color: transparent;
        color: var(--primary-color);
        margin-bottom: 8px;
        border: none;

        &:hover {
            margin-bottom: 6px;
            border-bottom: 2px solid var(--primary-color);
            cursor: pointer;
        }

        &.disabled {
            background-color: $color-grey;
            cursor: not-allowed;
        }

        &.no-underline:hover {
            margin-bottom: 8px;
            border-bottom: none;
            cursor: pointer;
        }
    }


}

.tab-button {
    @include text(14px, white, 600, 20px);
    text-align: center;
    letter-spacing: 0.3px;
    border-radius: 4px;
    box-shadow: none;
    border: none;
    text-decoration: none;
    text-transform: uppercase;

    background-color: transparent;
    border-radius: 0;
    padding: 0 0 4px 0;
    width: max-content;
    margin-right: 20px;
    font-weight: 400;
    z-index: 999;
    @include text(16px, $color-primary-text, 500, 24px);

    &:hover,
    &.active {
        cursor: pointer;
        color: var(--primary-color) !important;
        text-decoration-line: underline;
        text-decoration-thickness: 1.9px;
        text-decoration-color: var(--primary-color);
        text-underline-offset: 10.5px;
        // border-bottom: 2px solid var(--primary-color);
    }
}