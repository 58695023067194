@import '../../../../scss/variables.scss';
@import '../../../../scss/mixins.scss';

.retail-layout {
    .primary {
        @include button();

        &.large {
            padding: 8px 48px;
        }
    }

    .secondary {
        @include button($border: 1px solid var(--primary-color), $background-color: white, $color: var(--primary-color));

        &:hover {
            @include button();
        }

        &.large {
            padding: 8px 48px;
        }
    }

    .disabled {
        background-color: $retail-color-border;
        cursor: not-allowed;
    }

    .full-width {
        width: 100%;

        &:hover {
            width: 100%;
        }
    }
}