@import './scss/_mixins.scss';
@import './scss/_variables.scss';

.retail-layout {
    font-family: 'Inter';

    .retail-container {
        margin: 0 auto;
        max-width: $max-width;
        padding-left: 24px;
        padding-right: 24px;

        .checkout-heading {
            @include text(32px, #2C2C2C, 600, 48px, $mobile-size: 24px, $mobile-weight: 600, $mobile-line-height: 36px);
        }
    }

    .main {
        @include spacing($margin: 0, $padding: (64px 0 96px 0), $mobile-padding: (48px 0 96px 0));
    }

    .section-heading {
        @include text(32px, $retail-color-black, 600, 38px);
    }

    .secondary-mobile-heading {
        @include text ($mobile-size: 28px, $mobile-color: #2C2C2C, $mobile-weight: 600, $mobile-line-height: 42px);
        text-align: center;
    }

    .title {
        @include text(20px, $retail-color-black, 600, 28px);
    }

    .primary-text {
        @include text(14px, $retail-color-black, 400, 20px);
    }

    .subheading {
        @include text(18px, $retail-color-black, 400, 27px);
    }

    .secondary-text {
        @include text(12px, $retail-color-secondary-text, 400, 16px);
        text-transform: uppercase;
    }

    .secondary-heading {
        @include text(32px, $retail-color-black, 600, 48px, 24px, $retail-color-black, 600, 36px);
    }

    .icon-description {
        @include text(12px, $retail-color-black, 400, 16px);
    }

    .cta {
        @include text(14px, $retail-color-link, 400, 20px);
        @include cta();
    }

    .primary-heading {
        @include text(48px, $retail-color-black, 700, 62px, 32px, $retail-color-black, 700, 38.4px);

        @media screen and (max-width: $mobile-breakpoint) {
            text-align: center;
        }
    }

    .page {
        // @include spacing($margin: 0, $padding: (64px 0px 96px 0px), $mobile-padding: (48px 0px 96px 0px));
        // min-height: 1000px;

        &.blurred {
            filter: blur(1px);

            /* Firefox version 34 and earlier */
            // filter: url("blur.svg#gaussian_blur");

            /* Webkit in Chrome 52, Safari 9, Opera 39, and earlier */
            -webkit-filter: blur(1px);
        }
    }

    .error-backend {
        margin-top: -15px;
    }

    .hero-image {
        width: 100%;
        max-width: 578px;

        @media screen and (max-width: $mobile-breakpoint) {
            max-width: 100%;
        }
    }

    .intro-card {
        @include card ($width: 100%, $border: none, $border-radius: 0px, $background-color: rgba(218, 251, 245, 0.2), $max-width: 0, $min-width: 0, $max-height: 0, $min-height: 0);
        @include spacing(0, (32px 0 45px 0px), 0, (48px 0 45px 0px));

        .in-partnership-with {
            @include text(14px, $retail-color-cool-grey, 600, 21px);
        }
    }

    .partner-logo {
        @include image($width: 112px, $height: 112px, $mobile-width: 80px, $mobile-height: 80px);
        @include spacing($margin: (0 24px), $mobile-margin: (0 16px));
    }

    .partners-card {
        @include card ($width: 100%, $border: none, $border-radius: 0px, $background-color: white, $max-width: 0, $min-width: 0, $max-height: 0, $min-height: 0);
        padding: 64px 0;
    }

    .deals-card,
    .explore-offerings {
        background: url('/assets/retail/images/bg.svg'), linear-gradient(0deg, rgba(240, 240, 240, 0.3), rgba(240, 240, 240, 0.3)), #FFFFFF;
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: fixed;
        background-color: #F8F8F8;
        @include card ($width: 100%, $border: none, $border-radius: 0px, $max-width: 0, $min-width: 0, $max-height: 0, $min-height: 0);
        @include spacing(0, (48px 0px 96px 0), 0, (36px 0px 48px 0));
        min-height: 600px;

        @media screen and (max-width: $mobile-breakpoint) {
            background: url('/assets/retail/images/bg.svg'), linear-gradient(0deg, rgba(240, 240, 240, 0.3), rgba(240, 240, 240, 0.3)), #FFFFFF;
            // background: url('/assets/retail/images/more-phone.svg'), linear-gradient(0deg, rgba(240, 240, 240, 0.3), rgba(240, 240, 240, 0.3)), #FFFFFF;
            background-repeat: repeat;
        }

    }

    .landing-background {
        background: url('/assets/retail/images/deals-background.svg');
        background-repeat: no-repeat;
        background-size: cover;
        @include card($min-height: 300px, $min-width: 100%, $border-radius: 0);
        @include spacing(0, (82px 0), 0, (64px 0 228px 0));
        text-align: left;

        .exclusive {
            @include text(20px, white, 400, 24px, $mobile-size: 16px, $mobile-weight: 400, $mobile-line-height: 24px);
            margin-bottom: 24px;

            @media screen and (max-width: 991px) {
                text-align: center;
            }

            .name {
                font-weight: 700;
            }
        }

        .heading {
            @include text(32px, white, 700, 36px, $mobile-size: 24px);
            @include spacing((0 0 12px 0), 0, (0 0 4px 0), 0);

            @media screen and (max-width: 991px) {
                text-align: center;
            }

        }

        .subheading {
            @include text(32px, white, 500, 36px, $mobile-size: 24px);

            @media screen and (max-width: 991px) {
                text-align: center;
            }
        }
    }

    .sidebar-text {
        @include text(18px, $retail-color-black, 400, 27px);
        border-left: 4px solid $retail-color-primary;
        @include spacing($margin: (40px 0 0 0), $mobile-margin: (24px 0 0 0), $padding: (0 0 0 24px), $mobile-padding: (0 0 0 0));

        @media screen and (max-width: $mobile-breakpoint) {
            // border-top: 4px solid $retail-color-primary;
            border-left: none;
            text-align: center;
        }
    }

    .checkout-card,
    .post-payment-card {
        @include card ($width: 100%, $border: none, $border-radius: 0px, $background-color: white, $max-width: 0, $min-width: 0, $max-height: 0, $min-height: 0);

        .secondary-heading {
            @include text ($size: 32px, $color: #2C2C2C, $weight: 600, $line-height: 120%, $mobile-size: 24px, $mobile-line-height: 32px);
        }

        .post-payment-description {
            @include text ($size: 18px, $color: #2C2C2C, $weight: 400, $line-height: 120%, $mobile-size: 14px);
        }


    }

    .checkout-card {
        padding-top: 48px;

        @media screen and (max-width: $mobile-breakpoint) {
            padding-top: 32px;
        }
    }

    .logout-icon {
        font-size: 28px;
        color: $retail-color-black;
    }

    .sticky-footer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 100;
        color: white;
        text-align: center;

        .elem {
            background-color: black;
            height: 50px;
        }
    }

    .offerings {
        .product-background {
            @include card($min-height: 300px, $min-width: 100%, $border-radius: 0);
            background: no-repeat;
            background-position: center;
            background-size: contain;
        }

        .explore-offerings {
            @include spacing(0, (48px 0 96px 0), 0, (64px 0 96px 0));
            background-color: #F8F8F8;
        }

        .secondary-heading {
            @media screen and (max-width: $mobile-breakpoint) {
                @include text($mobile-size: 28px, $mobile-weight: 600, $mobile-line-height: 42px);
                text-align: center;
            }
        }

        .divider {
            margin-left: auto;
            margin-right: auto;
            border-bottom: 1px solid black;
            width: 64px;
            margin-top: 12px
        }
    }

    .checkout-form {
        @include spacing($margin: (20px 0px 0px 0px), $padding: 32px, $mobile-padding: (24px 0 24px 0), $mobile-margin: (0px 0 0px 0));
        @include card ($width: 100%, $border: 1px solid #DCDCDC, $border-radius: 8px);
        @media screen and (max-width: $mobile-breakpoint) {
            border: None;
        }

        .checkout-form-heading {
                @include text(20px, $retail-color-black, 600, 30px);
                margin-bottom: 24px;

            .note {
                @include text(14px, $retail-color-black, 400, 20px, 14px, $retail-color-black, 400, 20px);

                .note-title {
                    font-weight: 500;
                }
            }

        }
    }

    .checkout-cart-items-card {
        margin-top: 20px;
        margin-bottom: 96px;
        @include spacing($margin: (20px 0px 0px 0px), $padding: 32px, $mobile-padding: (24px 0 12px 0), $mobile-margin: (0px 0 0px 0));
        @include card ($width: 100%, $border: 1px solid #DCDCDC, $border-radius: 8px);

        .heading {
            @include text(20px, $retail-color-black, 600, 30px);
            margin: 24px 0;

            .cart-item-counter {
                color: $retail-color-text-grey;
            }
        }

        @media screen and (max-width: $mobile-breakpoint) {
            margin-top: 0px;
            margin-bottom: 0px;
            border-left: none;
            border-right: none;
            border-width: 4px;
            border-radius: 0;
        }
    }

    .truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .ml-8 {
        margin-left: 8px;
    }

    .mb-36 {
        margin-bottom: 36px;
    }
}

.empty-cart {
    .subtitle {
        @include text ($size: 18px, $color: #2C2C2C, $weight: 400, $line-height: 28px);
        text-align: center;
    }

    .primary-heading {
        @include text ($size: 32px, $color: #2C2C2C, $weight: 700, $line-height: 62px, $mobile-size: 28px, $mobile-line-height: 46px);
        text-align: center;
    }
}

