@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';

.retail-layout {

  .retail-header {
    // border-bottom: 1px solid $retail-color-border;
    height: 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .retail-header-strip {
      // @include spacing(0, (auto 16px), 0, (auto 0px));

      .logo-image {
        width: 100%;
        max-height: 60px;

        @media screen and (max-width: 991px) {
          width: 80%;
        }
      }
    }

    @media screen and (max-width: 991px) {
      height: 80px;
    }

    .cta {
      max-width: 200px;
    }
  }
}


.text-back-cta {
  color: $color-process-blue;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
      color: $color-process-blue;
      opacity: 0.8;
  }
}