@import '../../scss/variables.scss';
@import '../../scss/mixins.scss';


.breaker {
    width: 64px;
    height: 0px;
    border: 2px solid var(--primary-color);
    background-color: var(--primary-color);
    margin-top: 12px;
    margin-bottom: 12px;

    &.ct {
        margin-left: auto;
        margin-right: auto;
    }
}

.uhi-upgradation {

    .logo {
        width: 180px;
        cursor: pointer;
    }

    .active-tab-head {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        cursor: pointer;

        &:hover {
            color: var(--primary-color);
        }

        .back {
            margin-right: 8px;
        }
    }

    .step-top-margin {
        margin-top: 24px;
    }

    .header {
        border-bottom: 1px solid $color-base-grey;
        @include spacing(0, (36px 0px), 0, (24px 0));

    }

    .fti-container {
        margin: 0 auto;
        max-width: calc($max-width + 48px);
        padding-left: 24px;
        padding-right: 24px;
    }

    .title {
        margin-top: 114px;
        font-weight: 700;
        font-size: 48px;
        line-height: 62px;
        color: $color-dark-blue-grey;

        .highlighted {
            color: var(--primary-color);
            ;
        }
    }

    .description {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: -0.2px;
        color: $color-dark-blue-grey;

        .subpoint {
            margin-top: 12px;
        }
    }

    .upgrade-cta {
        margin: 50px 0 40px 0;

        .btn {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
            color: $color-light-skyblue;
            padding: 8px 16px;
            height: 60px;
            width: 170px;
        }
    }

    .manipal-logo {
        margin-bottom: 40px;
    }

    // .fti-container {
    //     margin: 0 auto;
    //     max-width: calc($max-width + 48px);
    //     padding-left: 24px;
    //     padding-right: 24px;
    // }


    @media (max-width: 768px) {

        .insurance-partner {
            margin-top: 48px;
            margin-bottom: 12px;
        }

        .title {
            margin-top: 48px;
            font-weight: 700;
            font-size: 32px;
            line-height: 125%;
            text-align: center;
            padding: 10px 0 10px 0;
        }

        .description {
            font-size: 14px;
            line-height: 150%;
            text-align: center;
        }


        .upgrade-cta {
            margin: 32px 0 20px 0;
        }

        .manipal-logo {
            margin-bottom: 60px;
        }


        .upgrade-cta {
            .btn {
                margin: auto;
            }
        }

    }

    @media screen and (max-width: 993px) {
        .ftih {
            padding: 0px;

            .fti-container {
                padding: 24px;
            }
        }
    }
}

.base {
    .heading {
        @include spacing((64px 0px 16px 0px), 0, (40px 0px 16px 0px), 0);
        @include text(32px, $color-primary-text, 600, 38.4px, 24px, $color-primary-text, 600, 32px);
    }

    &.main {
        @include spacing((48px 0 0 0), 0, (32px 0 0 0), 0);
    }
}

.benefits-layout {
    background: white;

    .purchase-now-cta {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: $color-light-skyblue;
        padding: 8px 16px;
        height: 60px;
        width: 170px;
        margin: 80px 0 128px 0;
    }

    .subtitle {
        margin-top: 60px;
        text-align: center;
        // margin-bottom: 40px;
        font-family: 'Inter';
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: $color-black;
    }

    .description {
        margin-bottom: 72px;
        font-family: 'Inter';
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $color-black;
    }

    .breaker {
        margin-top: 16px;
        margin-bottom: 8px;
    }

    @media (max-width:768px) {
        .subtitle {
            text-align: left;
        }

        .breaker {
            margin-left: 0;
        }

        .description {
            margin-bottom: 4px;
        }

        .purchase-now-cta {
            margin: 50px 0 65px 0;
            display: block;
            width: 100%;
            height: 48px;
        }
    }

    .major-benefit {
        .benefit-image {
            height: 96px;
            width: 96px;
        }

        .benefit-title {
            margin-top: 24px;
            font-family: 'Inter';
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: $color-black;
            text-align: center;
        }

        .benefit-description {
            margin-top: 12px;
            font-family: 'Inter';
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $color-grey;
            text-align: center;
        }

        @media (max-width: 768px) {
            margin-top: 48px;

            .benefit-image {
                height: 60px;
                width: 60px;
            }


            .benefit-title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                line-height: 13px;
                color: $color-black;
                flex: none;
                order: 1;
                flex-grow: 1;
                text-align: left;
            }

            .benefit-description {
                margin-top: 12px;
                font-family: 'Inter';
                font-weight: 400;
                font-size: 11px;
                line-height: 13px;
                color: $color-grey;
                text-align: left;
            }

            .subtitle {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 150%;
                color: $color-blue-grey;
                margin-top: 48px;
                margin-bottom: 0px;
            }

            .description {
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                text-align: center;
                letter-spacing: -0.2px;
                margin-bottom: 0px;
                margin-top: 12px;
            }

        }
    }

    .benefit {
        margin-top: 60px;

        .benefit-title {
            margin-top: 12px;
            font-family: 'Inter';
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: $color-black;
            text-align: center;
        }

        .image {
            margin-left: 16px;
        }

        @media (max-width: 768px) {
            .benefit-title {
                text-align: left;
            }

            .image {
                margin-left: 0px;
            }


        }

    }

}

.how-uhi-works-layout {
    width: 100vw;
    // background-color: $color-light-skyblue;
    background: linear-gradient(0deg, rgba(240, 240, 240, 0.3), rgba(240, 240, 240, 0.3)), white;
    padding-bottom: 90px;

    .title {
        margin-top: 96px;
    }

    .contact {
        margin-top: 48px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $color-black;
        text-align: center;

        a {
            color: var(--primary-color);
        }
    }

    .breaker {
        margin-bottom: 52px;
    }

    .five-l {
        background-color: $color-light-blue-grey;
        height: 16px;
        width: 100%;
    }

    .ten-l {
        background-color: $bright-green;
        height: 16px;
        width: 100%;
    }

    .twenty-l {
        background-color: var(--primary-color);
        height: 16px;
        width: 100%;
    }

    .amount-in-between {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid black;
        line-height: 0.1em;
        margin: 20px 0 20px;
        font-family: 'Inter';
        font-weight: 500;
        font-size: 20px;
        color: $color-black;

    }

    .amount-in-between span {
        // background: $color-light-skyblue;
        background: linear-gradient(0deg, rgba(240, 240, 240, 0.3), rgba(240, 240, 240, 0.3)), white;
        padding: 0 10px;
    }

    .bill-bar {
        margin-top: 16px;
    }

    .bar-text {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        font-feature-settings: 'kern' off;
        color: $color-black;
        margin-top: 8px;
        margin-bottom: 30px;
    }

    .renewal-year-sceneriaos {
        margin-top: 36px;
        margin-bottom: 36px;
        background: $bright-green;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        flex-direction: row;
        justify-content: center;
        height: 16px;
        width: 100%;
        padding: 16px 16px;
        border-radius: 4px;
        border: none;
        color: white;
    }



    @media (max-width: 768px) {
        .title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 125%;
            text-align: center;
            flex: none;
            order: 2;
            flex-grow: 0;
            margin-top: 64px;
        }

        .uhi-breakdown {
            margin-bottom: 50px;
        }

        .amount-in-between {
            font-family: 'Inter';
            font-weight: 500;
            font-size: 10px;
            line-height: 0.1em;
            color: $color-black;
        }

        .bar-text {
            font-family: 'Inter';
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            font-feature-settings: 'kern' off;
            color: $color-black;
        }

        .renewal-year-sceneriaos {
            font-family: 'Inter';
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            display: flex;
            align-items: center;
            letter-spacing: 0.3px;
            color: white;
            width: 100%;
        }

    }

    .renewal-card {
        background-color: #EFEEEA;
        border-radius: 8px;
        padding: 24px;
        width: 100%;

        .card-title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 150%;
            text-align: center;
            color: var(--primary-color);
            margin-bottom: 24px;
        }

        .description {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 150%;
            letter-spacing: -0.2px;
            color: $color-dark-blue-grey;
            opacity: 0.9;
            text-align: center;
        }

        .caption {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 150%;
            letter-spacing: -0.2px;
            color: $color-dark-blue-grey;
            opacity: 0.9;
            margin-top: 10px;
            margin-bottom: 20px;
            text-align: center;
        }

        .cta-btn {
            background: $color-light-yellow;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-family: 'Inter';
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            margin-right: 10px;
            padding: 8px 16px;
            border-radius: 4px;
            border: none;
            width: 90%;
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 10px;
            line-height: 150%;
            text-transform: uppercase;
            color: $color-dark-blue-grey;
        }

        @media (max-width: 768px) {
            margin-top: 24px;

            .card-title {
                margin-top: 0px;
            }
        }

    }


}

.family-members {
    @include spacing((0 0 48px 0), 0, (0 0 24px 0), 0);

    .heading {
        font-family: 'Inter';
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: $color-black;
    }

    .subheading {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: $color-black;
        margin-top: 4px;
    }

    .notes-and-premium {
        margin-top: 50px;
    }

    .si-and-deductible {
        .title {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.3px;
            color: black;
            margin: 48px 0 24px 0;
        }

        .key,
        .value {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: black;
        }

        .key {
            width: 96px;
        }

        @media (max-width: 768px) {
            .title {
                text-align: left;
            }

        }

    }

    .footnote {
        font-family: 'Inter';
        font-size: 13px;
        line-height: 16px;
        color: black;
    }

    .footnote-title {
        font-family: 'Inter';
        font-size: 13px;
        line-height: 16px;
        color: black;
        font-weight: 500;
    }

    .premium-amount {
        font-family: 'Inter';
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: $color-black;
    }

    .amount {
        font-family: 'Inter';
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: $bright-green;
        margin-bottom: 12px;
    }


    @media (max-width: 768px) {
        padding-bottom: 100px;

        .heading {
            font-size: 16px;
            // text-align: center;
            line-height: 19px;
        }

        .subheading {
            font-size: 10px;
            line-height: 12px;
            text-align: center;
        }

        .premium {
            text-align: center;
            margin-top: 40px;
            margin-bottom: 28px;
        }
    }

}

.personal-details {
    .form {
        margin-top: 29px;
    }
}


.post-payment-head-strip {
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    border-bottom: 1px solid $color-base-grey;
    @include spacing(0, (36px 0px), 0, (24px 0));
    padding-left: 24px !important;
    padding-right: 24px !important;

    &:hover {
        color: var(--primary-color);
    }

}

.post-payment {
    padding-bottom: 60px;

    @media screen and (max-width: 993px) {
        padding-left: 24px;
        padding-right: 24px;
    }

    .heading {
        @include text(48px, $color-dark-blue-grey, 700, 62px, 24px, $color-dark-blue-grey, 600, 32px);
        @include spacing(0, (64px 0px 16px 0px), 0, (48px 0px 16px 0px));
        text-align: center;
    }

    .subheading {
        @include text(18px, $color-dark-blue-grey, 400, 27px, 16px, $color-dark-blue-grey, 400, 24px);
        text-align: center;

        .smaller {
            margin-top: 16px;
            font-size: 14px;
            line-height: 18px;
        }
    }

    .loader {
        @include spacing(0, (112px 0px 0px 0px), 0, (112px 0px 0px 0px));
    }

    .image {
        @include spacing(0, (16px 0px 32px 0px), 0, (16px 0px 32px 0px));
    }

    .cta {
        @include spacing(0, (32px 0px 0px 0px), 0, (32px 0px 0px 0px));
    }
}

.footer-summary {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999;
    margin-left: -24px;
    box-shadow: 0px -2px 8px 0px #00000040;
    background-color: white;

    @media screen and (max-width: $desktop-breakpoint) {
        display: block;
    }
}

.fix-bottom {
    padding: 0 24px 24px 24px;
    cursor: pointer;

    .amount {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        color: #4B5054;
    }

    hr {
        width: 40px;
        background-color: #302D3C;
        height: 2px;
        border: none;

    }
}