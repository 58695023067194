@import './scss/_mixins.scss';
@import './scss/_variables.scss';


.retail-layout {
    .ant-modal-body {
        padding: 0px !important;
    }

    .ant-divider-horizontal {
        margin: 0px !important;
    }

    // Select
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid $retail-color-text-grey;
        border-radius: 4px;
        color: $retail-color-text-grey;

        &:hover {
            border: 1px solid $retail-color-text-grey;
        }
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid $retail-color-text-grey;
    }

    .ant-select-item-option-active,
    .ant-select-item-option-selected {
        background-color: var(--primary-color-lighter) !important;
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border: 1px solid $retail-color-text-grey;
    }

    .ant-form-item-label {
        padding: 0 !important;
        margin-bottom: 4px;
    }

    .ant-form-item {
        margin-bottom: 16px;
    }

    .ant-form-item-label>label {
        @include text(14px, $retail-color-form-label, 400, 20px);
    }

    .ant-input {
        @include text(14px, $retail-color-form-label, 400, 20px);
        @include spacing(0, (8px 0px 8px 12px), 0, (8px 0px 8px 12px));
        // border-color: $retail-color-tertiary-text;
        border-color: #DCDCDC;
        border-radius: 4px;
        border-width: 1px;
        height: 36px;

        &:hover,
        &:focus {
            border-color: var(--primary-color) !important;
            border-radius: 4px;
            box-shadow: none;
        }

        .ant-input-status-error {
            box-shadow: none;
        }
    }

    .ant-spin-dot-item {
        background-color: var(--primary-color);
    }

    .ant-btn-primary {
        background-color: var(--primary-color);
        border-color: var(--primary-color);

        &:hover,
        &:active {
            background-color: var(--primary-color);
            border-color: var(--primary-color);
        }
    }

    .ant-notification-notice {
        background-color: $retail-color-black;
    }

    .ant-notification-notice-close-icon,
    .ant-notification-notice-description,
    .ant-notification-notice-message,
    .ant-notification-notice-icon-success {
        color: white !important;
    }

    .ant-form-item-required::before {
        display: none !important;
    }

    .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
        display: inline-block;
        margin-left: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: 'Inter', sans-serif;
        line-height: 1;
        content: '*';
    }

    .ant-divider-with-text {
        border-top-color: $retail-color-text-grey !important;
        border-width: 0.5px;
    }

    .mt-4 {
        margin-top: 4px;
    }

    .mt-16 {
        margin-top: 16px;
    }

    .mt-32 {
        margin-top: 32px;
    }

    .mt-40 {
        margin-top: 40px;
    }

    .mt-48 {
        margin-top: 48px;
    }

    .mb-48 {
        margin-bottom: 48px;
    }

    .mt-64 {
        margin-top: 64px;
    }

    .text-center {
        text-align: center;
    }

    .blue-cta {
        @include cta;
        color: $retail-color-link;
    }

    .full-width {
        width: 100%;
        max-width: $max-width;
    }

    .highlight {
        color: var(--primary-color);
    }

    .strikethrough {
        text-decoration: line-through;
    }

    .no-margin-sides {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .center-align {
        margin: 0 auto;
    }
}