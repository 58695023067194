@import './_variables.scss';

@mixin text ($size: 14px, $color: $color-black, $weight: 400, $line-height: 20px, $mobile-size: 0, $mobile-color: 0, $mobile-weight: 0, $mobile-line-height: 0) {
    font-size: $size;
    font-weight: $weight;
    line-height: $line-height;
    color: $color;

    @media screen and (max-width: 768px) {
        @if $mobile-size !=0 {
            font-size: $mobile-size;
        }

        @if $mobile-color !=0 {
            color: $mobile-color;
        }

        @if $mobile-weight !=0 {
            font-weight: $mobile-weight;
        }

        @if $mobile-line-height !=0 {
            line-height: $mobile-line-height;
        }
    }
}

@mixin card ($width: 100%, $border: 1px solid $color-base-grey, $border-radius: 8px, $background-color: white, $max-width: 0, $min-width: 0, $max-height: 0, $min-height: 0, $height: 0, $box-shadow: None) {
    border: $border;
    border-radius: $border-radius;
    background-color: $background-color;
    width: $width;

    @if $max-width !=0 {
        max-width: $max-width;
    }

    @if $min-width !=0 {
        min-width: $min-width;
    }

    @if $max-height !=0 {
        max-height: $max-height;
    }

    @if $min-height !=0 {
        min-height: $min-height;
    }

    @if $height !=0 {
        height: $height;
    }

    @if $box-shadow !=None {
        box-shadow: $box-shadow;
    }
}

@mixin spacing ($margin: 0, $padding: 0, $mobile-margin: 0, $mobile-padding: 0) {
    margin: $margin;
    padding: $padding;

    @media screen and (max-width: $mobile-breakpoint) {
        margin: $mobile-margin;
        padding: $mobile-padding;
    }

}

@mixin image ($width: 100%, $height: auto, $max-width: 0, $max-height: 0, $mobile-width: 0, $mobile-height: 0, $border-radius: 0) {
    width: $width;
    height: $height;

    @if $max-width !=0 {
        max-width: $max-width;
    }

    @if $max-height !=0 {
        max-height: $max-height;
    }

    @if $border-radius !=0 {
        border-radius: $border-radius;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        @if $mobile-width !=0 {
            width: $mobile-width;
        }

        @if $mobile-height !=0 {
            height: $mobile-height;
        }
    }

}

@mixin button ($padding: 8px 16px, $border-radius: 4px, $border: none, $background-color: var(--primary-color), $color: white) {
    @include text(14px, $color, 600, 20px);
    @include spacing(0, $padding, 0, $padding);
    @include card($border: $border, $border-radius: $border-radius, $max-height: 36px, $width: auto);
    @include cta();
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    background-color: $background-color;
}

@mixin cta {
    cursor: pointer;
}

@mixin divider ($width: 100%, $border: 0.5px solid $color-base-grey, $margin: 0, $margin-mobile: 0) {
    width: $width;
    border: $border;

    @if $margin !=0 {
        margin: $margin;
    }

    @media screen and (max-width: 768px) {
        @if $margin-mobile !=0 {
            margin: $margin-mobile;
        }
    }
}