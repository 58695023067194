@import '../../../scss/mixins';
@import '../../../scss/variables';

// .retail-layout {
.retail-login-modal {
    .text-center {
        text-align: center;
    }

    .ant-modal-body {
        padding: 0px;

        @media screen and (max-width: 991px) {
            padding: 0px;
        }
    }

    @include card ($border: 1px solid $retail-color-border, $border-radius: 8px, $background-color: white, $max-width: 480px);
    box-shadow: 0px 0px 32px rgba(62, 73, 70, 0.3);
    padding: 0;

    @media screen and (max-width: $mobile-breakpoint) {
        max-width: 350px;
    }

    .content {
        @include spacing(0, (72px 112px), 0, (48px));
    }
}

// }